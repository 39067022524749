import React, {createContext, useEffect, useState} from 'react';
import { saveAs } from 'file-saver';
import {getUser} from '@/lib/api';
import {useSession} from 'next-auth/react';


export const AppContext = createContext();

export function AppProvider({ children }) {

    const { data: session, status } = useSession();

    const [initialLoad, setInitialLoad] = useState(true);

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [isLanguageMenuOpened, setIsLanguageMenuOpened] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName ] = useState('');
    const [email, setEmail] = useState('');

    const [currentAlgoliaQuery, setCurrentAlgoliaQuery] = useState('');
    const [selectedAlgoliaObject, setSelectedAlgoliaObject] = useState('');

    useEffect(() => {

        const currentDate = new Date();
        const currentUTCDate = new Date(currentDate.toISOString());

        // Get User details
        if (session) {
            getUserData().then();
        }

    }, []);

    const getUserData = async () => {
        const response = await getUser(session?.user?.id);

        setFirstName(response.first_name);
        setLastName(response.last_name);
        setEmail(response.email);
    }


    const handlePrint = (e, printout) => {
        fetch(printout.file)
            .then(function (response) {
                return response.blob();
            })
            .then(function (myBlob) {
                let objectURL = URL.createObjectURL(myBlob);
                document.querySelector('#pdf-frame').src = '';
                document.querySelector('#pdf-frame').src = objectURL;
                objectURL = URL.revokeObjectURL(myBlob);
            })
            .then(function () {
                window.setTimeout(function () {
                    document.querySelector('#pdf-frame').contentWindow.print();
                }, 100);
            });
    };

    const handleDownload = (e, printout) => {
        e.preventDefault();

        let printoutFile = printout.file;

        if (printout.download_url) {
            printoutFile = printout.download_url;
        }

        fetch(printoutFile, {
            method: 'GET',
        })
            .then((response) => {
                if (response.ok) {

                    return response.blob().then((blob) => {
                        saveAs(blob, printoutFile);
                    });
                } else {
                    return response.text().then(function (error) {
                        throw new Error(error);
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const customImageLoader = (src, width, height, quality ) => {
        return `${src}?w=${width}&h=${height}&q=${quality || 75}`;
    };

    const contextValues = {
        initialLoad,
        setInitialLoad,

        selectedLanguages,
        setSelectedLanguages,
        isLanguageMenuOpened,
        setIsLanguageMenuOpened,

        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,

        currentAlgoliaQuery,
        setCurrentAlgoliaQuery,
        selectedAlgoliaObject,
        setSelectedAlgoliaObject,

        handlePrint,
        handleDownload,
        customImageLoader
    };

    return (
        <AppContext.Provider value={contextValues}>
            {children}
        </AppContext.Provider>
    );
}
